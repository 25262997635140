<template>
  <div class="section">
    <section class="container" v-loading="loading">
      <PullDownList disabled :total="listData.length">
        <el-row :gutter="20" style="display: flex; flex-wrap: wrap;">
          <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item,index) in listData" :key="index">
            <div class="product-item">
              <!--<div class="corner-mark">HOT</div>-->
              <a target="_blank" :href="`product-center/detail/${item.productType}/${item.productId}`">
                <div class="product-item__image-area">
                  <el-image class="product-item__image" fit="contain" :src="domainUrl+item.imageUrl | prefixFilter"/>
                  <!--<div class="compare-pre-sales"> {{ $i18n.t('products.new') }}</div>-->
                  <div class="new_flag">
                    <img v-if="item.newFlag" src="../../../../assets/image/product/new.png" alt="">
                    <img v-if="item.distributionFlag" src="https://resources.holowits.com.sg/product/Distribution.png" alt="">
                    <template v-if="item.tagList">
                      <span  v-for="(tag, i) in (item.tagList || '').split(';')" :key="i" class="tag">{{tag}}</span>
                    </template>
                  </div>
                </div>
                <div>
                  <div class="product-item__parameter">
                    {{ item.model }}
                  </div>
                  <div class="product-item__name">{{ item.summary }}</div>
                </div>
              </a>
              <!-- <div v-show="item.productClass" style="text-align: center;">
                <el-button type="text" icon="el-icon-plus" @click="addToCompare(item)">add to compare</el-button>
              </div> -->
              
              <!-- <div class="product-detail"> -->
                <!-- <div class="product-detail-item" v-if="item.model">
                  <span class="product-term">{{ $i18n.t('products.model') }}</span>
                  <span :title="item.model">{{ item.model }}</span>
                </div>
                <div class="product-detail-item" v-if="item.cpu">
                  <span class="product-term">{{ $i18n.t('products.processor') }}</span>
                  <span>{{ item.cpu }}</span>
                </div>
                <div class="product-detail-item" v-if="item.computingPower">
                  <span class="product-term">{{ $i18n.t('products.computingPower') }}</span>
                  <span>{{ item.computingPower }}</span>
                </div>
                <div class="product-detail-item" v-if="item.memory">
                  <span class="product-term">{{ $i18n.t('products.memory') }}</span>
                  <span>{{ item.memory }}</span>
                </div> -->
                <!-- <div class="fixed-handle">-->
                  <!-- <el-button size="small" type="primary">{{ $i18n.t('products.compareAdd') }}</el-button> -->
                <!-- </div> -->
              <!-- </div> -->
            </div>
          </el-col>
        </el-row>
      </PullDownList>
    </section>
  </div>
</template>

<script>
  import {getProductList, getAllProduct} from '../../../../api/product';
  import PullDownList from '../../../../components/PullDownList';
  import {mapActions} from "vuex"
  export default {
    name: 'ProductList',
    components: {
      PullDownList
    },
    data() {
      return {
        loading: true,
        listData: [],
      };
    },
    created() {
    },
    methods: {
      async search(queryParam) {
        this.loading = true
        const {data: {data}} = await getAllProduct(queryParam)
        this.$emit('load', data)
        setTimeout(() => {
          this.listData = data;
          this.loading = false
        }, 100)
        this.loading = false
      },
      getProductList(queryParam) {
        this.loading = true
        getProductList(queryParam).then(res => {
          this.$emit('load', res.data.data)
          setTimeout(() => {
            this.listData = res.data.data;
            this.loading = false
          }, 100)
        }).catch(() => {
          this.loading = false
        });
      },
      ...mapActions({
        addComparisonList: 'compare/addComparisonList'
      }),
      addToCompare(item) {
        this.addComparisonList(item)
      },
    },
    filters: {
			prefixFilter(val) {
				if (val) {
					return val.replace('https://resources.holowits.com.sg/', '')
				}
				return val
			}
		},
  };
</script>

<style scoped lang="less">
  .section {
    border-top: 1px solid rgb(244, 248, 249);
    border-bottom: 1px solid rgb(244, 248, 249);
    background: rgb(247, 250, 251);
    padding: 20px 0;
    position: relative;

    .container {
      min-height: 400px;
    }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   height: 1000px;
    //   background-color: rgb(247, 250, 251);
    // }
  }

  .product-item {
    background-color: #FFFFFF;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgb(236, 240, 240);
    display: flex;
    flex-direction: column;
    // height: 280px;
    overflow: hidden;
    transition: .2s all ease;
    position: relative;
    justify-content: space-between;

    &:hover {
      box-shadow: 0 0px 6px 2px rgba(52, 51, 51, 0.1);
      // border-color: var(--themeColor);
      .product-item__image {
        transform: scale(1.1);
      }
      

      .product-detail {
        opacity: 1;
        visibility: visible;
      }
    }

    .corner-mark {
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--themeColor);
      color: #FFFFFF;
      width: 80px;
      text-align: center;
      line-height: 1.5;
      font-size: 12px;
      transform: rotateZ(45deg);
      transform-origin: 40px 40px;
      z-index: 1;
    }

    .compare-pre-sales {
      display: inline-block;
      color: #ed9e17;
      border: 1px solid #ed9e17;
      padding: 0 8px;
      margin: 5px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .product-detail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, .98);
      padding: 30px 20px 15px;
      opacity: 0;
      will-change: opacity;
      transition: .02s ease;
      cursor: pointer;
      visibility: hidden;
      z-index: 2;

      .fixed-handle {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
      }

      .product-detail-item {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        font-size: 14px;
        align-items: baseline;
        margin-bottom: 15px;

        * {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .product-term {
          min-width: 80px;
          max-width: 80px;
          text-align: left;
          font-size: 14px;
          line-height: 16px;
          color: rgb(102, 102, 102);
          margin-right: 20px;
          overflow: visible;
        }
      }
    }

    .product-item__image-area {
      max-height: 220px;
      flex: 1;
      padding-top: 10px;
      text-align: center;
      background-color: #f7f8f8;
      padding-bottom: 10px;
    }

    .product-item__image {
      width: 100%;
      height: 152px;
      padding: 0 20px;
      transition: all 0.4s;
    }

    .new_flag {
      position: absolute;
      top: 20px;
      left: 26px;
      // width: 48px; 
      height: 24px;
      img + img {
        margin-left: 10px;
      }

      img + .tag {
        margin-left: 10px;
      }

      .tag {
        padding: 2.5px 6px;
        background-color: #c7000b;
        color: #FFFFFF;
        border-radius: 3px;
        vertical-align: middle;

        &+.tag {
          margin-left: 10px;
        }
      }
    }

    .product-item__parameter {
      font-size: 15px;
      line-height: 1.4;
      margin-top: 15px;
      margin-bottom: 8px;
      font-weight: 600;
      color: #000000;
    }

    .product-item__name {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 2px;
      color: #333333;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @media (min-width: 1200px){
    .container {
      width: 1400px;
    }
  }

</style>
