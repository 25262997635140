<template>
  <div>
    <Breadcrumb/>
    <section class="container">
      <FilterArea @change="filterChange" @search="searchProduct" :total="total"/>
    </section>
    <ProductList ref="productList" @load="productLoad"/>
    <!-- <CompareList/> -->
  </div>
</template>
<script>
  import Breadcrumb from '../../../components/Breadcrumb';
  import FilterArea from './components/FilterArea';
  import ProductList from './components/ProductList';
  import CompareList from './components/CompareList.vue';

  export default {
    name: 'productCenterList',
    components: {
      Breadcrumb,
      FilterArea,
      ProductList,
      CompareList
    },
    data() {
      return {
        total: 0,
      };
    },
    methods: {
      searchProduct(val) {
        this.$nextTick(() => {
          this.$refs.productList.search(val);
        });
      },
      filterChange(val) {
        this.$nextTick(() => {
          this.$refs.productList.getProductList(val);
        });
      },
      productLoad(val) {
        this.total = val.length;
      },
    },
  };
</script>
<style scoped lang="less">
@media (min-width: 1200px){
  .container {
    width: 1400px;
  }
}
</style>
